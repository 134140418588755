<template>
  <div class="layout-horizontal">
    <NavBar />
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue'

export default {
  components: {
    NavBar,
  },
}
</script>