<template>
  <div class="navigation">
    <div class="container">
      <ul>
        <li v-for="(item, index) of navgation" :key="index" :class="{ active: $route.fullPath === item.url }" >
          <router-link :to="item.url">
            <div class="icon orders" :class="item.class"></div>
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navgation: [
        {
          title: 'Orders',
          url: '/orders',
          class: 'orders'
        },
        {
          title: 'Stock',
          url: '/stock',
          class: 'stock'
        },
        {
          title: 'Cash register',
          url: '/cash-register',
          class: 'cash'
        },
        {
          title: 'Others',
          url: '/others',
          class: 'others'
        }
      ]
    }
  }
}
</script>
